import React from "react";
import Search from "../components/Search";

import "../scss/pages/home.scss";

const Home = () => {
  return (
    <>
      <div className="home">
        <div className="home-body">
          <img src="./logo_mexiconow.jpg" alt="Logo Mexiconow" width="272"/>
          <div className="home_inputContainer">
            <Search />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;

import { useState, useEffect } from "react";
import Papa from "papaparse";

const useCsvSearch = (customer) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("/Cliente-Vendedor.csv");
      const reader = response.body.getReader();
      const result = await reader.read();
      const decoder = new TextDecoder("utf-8");
      const csv = decoder.decode(result.value);
      const results = Papa.parse(csv, { header: true });
      const rows = results.data;
      const filterData = rows.filter(val => 
        val.Clientes.toLowerCase().trim().includes(customer.toLowerCase().trim())
        );
      setData(filterData);
    };
    fetchData();
  }, [customer]);

  return { data };
};

export default useCsvSearch;

import React, { useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { useHistory } from "react-router-dom";

import './Search.scss';
import { useStateValue } from "../context/Search/SearchContext";
import { SET_SEARCH_CUSTOMERS } from "../context/types";

const Search = ({ hideButtons = false }) => {
  const [{},dispatch] = useStateValue();
  const [input, setInput] = useState({
    search: "",
    loading: true
  });
  const history = useHistory();

  const search = (e) => {
    e.preventDefault();
    if(input.search.length > 0){
      dispatch({
        type: SET_SEARCH_CUSTOMERS,
        customer: input.search,
        loading: input.loading
      });
      history.push("/search");
    }
    
  };
  return (
    <form className="search">
      <div className="search-input">
        <SearchIcon className="search__inputIcon" />
        <input
          value={input.search}
          onChange={(e) => {
            setInput({...input, search:e.target.value});
          }}
          placeholder="Buscador de Clientes"
        />
      </div>
      {!hideButtons ? (
        <div className="search__buttons">
          <Button onClick={search} type="submit" variant="outlined">
            Buscar <KeyboardArrowRightIcon />
          </Button>
        </div>
      ) : (
        <div className="search__buttons">
          <Button
            onClick={search}
            className="search__buttonsHidden"
            type="submit"
            variant="outlined"
          >
            Buscar <KeyboardArrowRightIcon />
          </Button>
        </div>
      )}
    </form>
  );
};

export default Search;

import { SET_SEARCH_CUSTOMERS } from "../types";

export const initialState = {
  customer: '',
  loading: true 
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  const { type } = action;
  switch (type) {
    case SET_SEARCH_CUSTOMERS:
      return {
        ...state,
        customer: action.customer,
        loading: action.loading
      };
    default:
      return state;
  }
};

import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./index.scss";
import SearchReducer, { initialState } from "./context/Search/SearchReducer";

import { StateProvider } from "./context/Search/SearchContext";

ReactDOM.render(
  <React.StrictMode>
    <StateProvider initialState={initialState} reducer={SearchReducer}>
      <App />
    </StateProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();

import React from "react";
import { Link } from "react-router-dom";
import Search from "../components/Search";
import { useStateValue } from "../context/Search/SearchContext";
import useCsvSearch from "../hooks/useCsvSearch";

import "../scss/pages/searchPage.scss";

const SearchPage = () => {
  const [{ customer }, dispatch] = useStateValue();

  const { data } = useCsvSearch(customer);

  return (
    <div className="searchPage">
      <div className="searchPageHeader">
        <Link className="searchLogo" to="/">
          <img src="./logoMN.jpg" alt="Logo Mexiconow" width="65" height="65" />
        </Link>
        <div className="searchPageBody">
          <Search hideButtons />
        </div>
      </div>

      {customer && (
        <div className="searchResult">
          <p className="resultCount">
            {data.length} resultados para {customer}
          </p>

          {data.map((item, idx) => (
            <div key={idx} className="result">
              <div className="cliente">
                Cliente: {item.Clientes.toLowerCase()}
              </div>
              <div className="vendedor">Vendedor: {item.Vendedor}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchPage;
